//
//
//
//
//
//
//
//
//
//
//
//
//

import Topic from "./Topic.vue";
export default {
  name: "News",
  components: {
    Topic
  },
  data() {
    const allTopics = [{
      title: "Управление минимальным остатком в репрайсере",
      description: "Добавили во все типы сценариев в репрайсере возможность автоматически повышать цены при снижении остатков в карточке товара.",
      photo: require("@/assets/images/dashboard/news/30.05.2024.png"),
      link: "https://www.help.salesfinder.ru/tpost/2cu6y2jy91-upravlenie-minimalnim-ostatkom-v-reprais",
      date: "30.05.2024"
    }, {
      title: "Новый сценарий в репрайсере: компенсация СПП",
      description: "Добавили новый сценарий для автоматического изменения цен ваших товаров. Теперь вы можете компенсировать скидку от маркетплейса.",
      photo: require("@/assets/images/dashboard/news/28.05.2024.png"),
      link: "https://www.help.salesfinder.ru/tpost/ad4dgs8kp1-novii-stsenarii-v-repraisere-kompensatsi",
      date: "28.05.2024"
    }, {
      title: "Автоматическое управление ценой: репрайсер от SalesFinder",
      description: "Настраивайте автоматические сценарии для управления ценой на свои товары и экономьте свое время с помощью репрайсера от SalesFinder",
      photo: require("@/assets/images/dashboard/news/07.05.2024.png"),
      link: "https://www.help.salesfinder.ru/tpost/opxva3buk1-avtomaticheskoe-upravlenie-tsenoi-reprai",
      date: "07.05.2024"
    }, {
      title: "Мониторинг РРЦ в SalesFinder",
      description: "С помощью нового модуля «Мониторинг РРЦ» вы сможете оперативно следить не только за ценами конкурентов, но и отслеживать цены на собственные товары.",
      photo: require("@/assets/images/dashboard/news/12.03.2024.png"),
      link: "https://www.help.salesfinder.ru/tpost/jzuvoiv3v1-monitoring-rrts-v-salesfinder",
      date: "12.03.2024"
    }, {
      title: "Показатель СПП теперь доступен в плагине SalesFinder",
      description: "Мы обновили расширение для браузеров и теперь показатель СПП доступен не только в отчётах по ВБ",
      photo: require("@/assets/images/dashboard/news/02.11.2023.png"),
      link: "https://www.help.salesfinder.ru/tpost/hx1r872541-pokazatel-spp-teper-dostupen-v-plagine-s",
      date: "02.11.2023"
    }, {
      title: "Новые метрики в отчётах внешней аналитики",
      description: "Вес, цена продавца, % выкупа уже доступны в аналитических отчётах SalesFinder",
      photo: require("@/assets/images/dashboard/news/01.11.2023.png"),
      link: "https://www.help.salesfinder.ru/tpost/5ch164h1z1-novie-metriki-v-otchyotah-vneshnei-anali",
      date: "01.11.2023"
    }, {
      title: "Узнайте рекламные ставки конкурентов прямо в кабинете Wildberries",
      description: "Плагин SalesFinder стал ещё удобнее! Теперь с его помощью вы можете получить ставки конкурентов прямо в рекламном кабинете WB",
      photo: require("@/assets/images/dashboard/news/13.10.2023.png"),
      link: "https://www.help.salesfinder.ru/tpost/phu6gmg0t1-uznaite-reklamnie-stavki-konkurentov-pry",
      date: "13.10.2023"
    }, {
      title: "Анализ рекламной выдачи товаров на Wildberries",
      description: "Обновление плагина для браузера SalesFinder позволит быстро проанализировать реальные рекламные ставки",
      photo: require("@/assets/images/dashboard/news/18.09.2023.png"),
      link: "https://www.help.salesfinder.ru/tpost/mihcx47ie1-analiz-reklamnoi-vidachi-tovarov-na-wild",
      date: "18.09.2023"
    }, {
      title: "Финансовый анализ магазина и товаров на Ozon",
      description: "Поможет оценить структуру расходов, экономическое здоровье магазина по ключевым показателям и финансовое состояние бизнеса",
      photo: require("@/assets/images/dashboard/news/14.09.2023.png"),
      link: "https://www.help.salesfinder.ru/tpost/34mybl1x11-finansovii-analiz-magazina-i-tovarov-na",
      date: "14.09.2023"
    }, {
      title: "В отчетах внешней аналитики Wildberries доступен анализ СПП",
      description: "WB больше не отображает СПП, но теперь метрика доступна в наших отчётах. 3 причины отслеживать скидки.",
      photo: require("@/assets/images/dashboard/news/13.09.2023.png"),
      link: "https://www.help.salesfinder.ru/tpost/regh543p31-v-otchetah-vneshnei-analitiki-wildberrie",
      date: "13.09.2023"
    }];
    return {
      allTopics,
      openedTopics: 8
    };
  },
  methods: {
    loadMore() {
      this.openedTopics += 8;
    }
  },
  computed: {
    topics() {
      return this.allTopics.filter((item, index) => index < this.openedTopics);
    }
  }
};